import React from 'react';
import About from './About';
import Contact from './Contact';
import Navbar from './Navbar';
import Services from './Services';
import repairing from './images/repairing.jpg';
import './Home.css'; // Import CSS file for additional styles
import Comments from './Comments';
import Copyright from './Copyright';
import NavNumber from './NavNumber';

function Home() {
  const handleCallUs = () => {
    window.location.href = 'tel:+971526061995'; // Replace with your phone number
  };
  
  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
    zIndex: -1
  };

  return (
    <div id="home" className="home-container">
      <NavNumber/>
      <Navbar />
      <div className="background-container m-3 p-3">
        <h1 className='text-center'>Welcome to our Repairing Center</h1>
        <p className='text-center'>We provide high-quality repair services for all your electronic devices.</p>
        <img src={repairing} alt="Repair Shop" className="background-image" />
        <button className="btn btn-primary btn-lg call-us-button" onClick={handleCallUs}>Call Us</button> {/* Added btn-lg class for larger size */}
      </div>
      <Services />
      <About />
      <Comments/>
      <Contact />
      <Copyright/>
      <div style={overlayStyle}></div>
    </div>
  );
}

export default Home;
