import React from 'react';
import './Services.css'; // Import the CSS file

function Services() {
  return (
    <div id='services' className="services-container">
      <h2 className="services-title text-center mb-4">Our Services</h2>
      <div className="services-cards row">
        <div className="card col-md-6 col-lg-3 mb-4">
          <h3 className="card-title text-center"><b>Washing Machine</b></h3>
          <p>Repair services tailored to your needs.</p>
          <p>Wide range of washing machine issues.</p>
          <p>Clients across UAE.</p>
          <p>Fast and responsive service</p>
          <p>Upfront and transparent pricing</p>
          <p>Diagnosing the issue to fixing the problem and testing the machine.</p>
        </div>
        <div className="card col-md-6 col-lg-3 mb-4">
          <h3 className="card-title"><b>Fridge Repairing</b></h3>
          <p>Reliable fridge repair solutions for years</p>
          <p>Expertly diagnose and fix your fridge issues.</p>
          <p>Use genuine parts to ensure long-lasting repairs and optimal performance.</p>
          <p>Available for urgent fridge repair needs to minimize food spoilage and inconvenience.</p>
        </div>
        <div className="card col-md-6 col-lg-3 mb-4">
          <h3 className="card-title"><b>AC Repairing</b></h3>
          <p>Swift response times to address your AC issues promptly and minimize discomfort</p>
          <p>Professionals equipped with the knowledge</p>
          <p>Industry-best practices to ensure lasting repairs and optimal performance</p>
          <p>Routine maintenance services to prevent breakdowns</p>
        </div>
        <div className="card col-md-6 col-lg-3 mb-4">
          <h3 className="card-title"><b>Motor Repairing</b></h3>
          <p>Accurate and efficient repairs.</p>
          <p>Motor repair services to address diverse needs and requirements</p>
          <p>High-quality components and parts to ensure durability, reliability, and optimal performance</p>
          <p>Efficient repair processes aim to restore motors</p>
        </div>
      </div>
    </div>
  );
}

export default Services;
