import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Routes, Route, BrowserRouter } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import Services from './Services';
import About from './About';
import Contact from './Contact';
import Whatsapp from './whatsapp'
import WhatsAppFunc from './whatsapp';
import Comments from './Comments';
import NavNumber from './NavNumber';

function App() {
  return (
    <BrowserRouter>
    <WhatsAppFunc/>
      <div>
        <Routes>
          <Route path='/navbar' element={<Navbar/>}></Route>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path='/whatsapp' element={<WhatsAppFunc/>}/>
          <Route path='/comments' element={<Comments/>}></Route>
          <Route path='/navNumber' element={<NavNumber/>}></Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
