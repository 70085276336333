import React from 'react';
import './Comments.css'; // Import custom CSS for styling

function Comments() {
    return (
        <div className="comments-container">
            <h1 className="comments-heading">Comments from Customers</h1>
            <div className="comment">
                <div className="comment-avatar">
                    {/* Include avatar image or icon */}
                </div>
                <div className="comment-content">
                    <p className="comment-text">"Exceptional service! I contacted Big City Repairing Company when my washing machine malfunctioned, and I couldn't be happier with the results. Their team exhibited professionalism and courtesy throughout the entire process. Not only did they swiftly diagnose the issue, but they also repaired my machine with precision and expertise. I highly recommend their services to anyone in need of reliable and efficient repairs. Thank you, Big City Repairing Company, for your outstanding work!"</p>
                    <p className="comment-author">- Muhammed Farrouq, UAE Nationals</p>
                </div>
                
            </div>
            <div className="comment">
                <div className="comment-avatar">
                    {/* Include avatar image or icon */}
                </div>
                <div className="comment-content">
                    <p className="comment-text">"Outstanding experience! When my refrigerator started acting up, I reached out to Big City Repairing Company, and they exceeded all expectations. Their team demonstrated exceptional professionalism and skill from start to finish. They quickly identified the problem and provided a prompt and effective solution. I couldn't be more impressed with their expertise and dedication to customer satisfaction. If you're seeking top-notch appliance repairs, look no further than Big City Repairing Company. Thank you for your stellar service!"</p>
                    <p className="comment-author">- Abbas Ahmed, UAE Nationals</p>
                </div>
                
            </div>
            {/* Add more comments with the same structure */}
        </div>
    );
}

export default Comments;
