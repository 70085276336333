import React, { useState } from 'react';
// import logo from './images/logo.png'; // Import your logo image

function Navbar() {
  // Define state to track whether the menu is open or closed
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Function to toggle the menu state
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white">
      <div className="container">
        <a className="navbar-brand" href='/'>
          {/* <img src={logo} alt="Logo" width="30" height="30" className="d-inline-block align-top" /> Adjusted margin here */}
          <span className="logo-text">Big City Repairing Center</span>
        </a>
        {/* Use the state to conditionally apply the 'show' class to the collapse div */}
        <div className={`collapse navbar-collapse justify-content-end ${isMenuOpen ? 'show' : ''}`} id="navbarNav"> {/* Added justify-content-end class here */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#services">Services</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about">About</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact">Contact Details</a>
            </li>
          </ul>
        </div>
        {/* Use the toggleMenu function as the onClick handler for the button */}
        <button className="navbar-toggler" type="button" onClick={toggleMenu}>
          <span className="navbar-toggler-icon" style={{ color: 'gray' }}></span>
        </button>
      </div>

      <style jsx>{`
        .navbar {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Change border color if needed */
          padding: 10px 0;
        }

        .navbar-brand {
          font-weight: bold;
          font-size: 1.2rem;
          color: black; /* Change navbar-brand color if needed */
        }

        .navbar-brand:hover {
          color: #ffc107; /* Change color on hover */
        }

        .logo-text {
          vertical-align: middle;
          margin-left: 10px; /* Add margin to separate logo from text */
        }

        .navbar-nav .nav-link {
          color: black; /* Change nav-link color if needed */
          font-weight: 500;
        }

        .navbar-nav .nav-link:hover {
          color: #ffc107; /* Change color on hover */
        }

        .navbar-toggler {
          border: none;
          outline: none;
          color: gray; /* Change navbar-toggler color to gray */
        }

        .navbar-toggler:hover {
          color: #ffc107; /* Change color on hover */
        }
      `}</style>
    </nav>
  );
}

export default Navbar;