import React from 'react';
// import showroomImage from './images/new.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

function Contact() {
  const sectionStyle = {
    // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${showroomImage})`, // Darken the background image
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '80vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: 'white',
    padding: '20px',
    position: 'relative'
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
    zIndex: -1
  };

  return (
    <section id="contact" style={sectionStyle}>
      <div className="container" style={{ position: 'relative', zIndex: 1 }}>
        <div className="row">
          <div className="col-md-4">
            <h3 className="mb-3 animated fadeInUp">Our Services</h3>
            <ul className="list-unstyled animated fadeInLeft">
              <li>Washing Machine</li>
              <li>Fridge</li>
              <li>Air Conditioner</li>
              <li>Motors</li>
              <li>Washing Machines</li>
              <li>Home Repairing Services</li>
            </ul>
          </div>
          <div className="col-md-4">
            <h3 className="mb-3 animated fadeInUp">Contact Us</h3>
            <p className="animated fadeInRight">+971526061995</p>
            <p className="animated fadeInRight">+971528570897</p>
            <p className="animated fadeInRight">+971568726375</p>

            <p className="animated fadeInRight">mintaifurniturenew@gmail.com</p>
          </div>
          <div className="col-md-4">
            <h3 className="mb-3 animated fadeInUp">Our Locations</h3>
            <p className="animated fadeInRight">Idustrial Area-6, Sharjah, UAE</p>
          </div>
        </div>
        {/* Add social media links */}
        <div className='mt-5 pt-5'>
        <div className="social-media-links mt-3" style={{ position: 'absolute', bottom: '5px', left: '50%', transform: 'translateX(-50%)', marginTop: '100px' }}>
          <a href="https://www.facebook.com/profile.php?id=100083141090025" target="_blank" rel="noopener noreferrer" style={{ marginRight: '20px', color: 'white', fontSize: '30px' }}>
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a href="https://www.instagram.com/repairing_workshop70?utm_source=qr&igsh=cng4cTZkbzh0emoz" target="_blank" rel="noopener noreferrer" style={{ marginRight: '20px', color: 'white', fontSize: '30px' }}>
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          {/* <a href="https://www.tiktok.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontSize: '30px' }}>
            <FontAwesomeIcon icon={faTiktok} /> */}
          {/* </a> */}
        </div>
        </div>
      </div>
      <div style={overlayStyle}></div>

      {/* Define CSS for animations */}
      <style jsx>{`
      body{font-family: "Gill Sans", times-new-romman;}

        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes fadeInLeft {
          from {
            opacity: 0;
            transform: translateX(-20px);
          }
          to {
            opacity: 1;
            transform: translateX(0);
          }
        }

        @keyframes fadeInRight {
          from {
            opacity: 0;
            transform: translateX(20px);
          }
          to {
            opacity: 1;
            transform: translateX(0);
          }
        }

        .animated {
          animation-duration: 1s;
          animation-fill-mode: both;
        }
      `}</style>
    </section>
  );
}

export default Contact;