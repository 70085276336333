import React from 'react';
import './About.css'; // Import custom CSS for styling
import washing from './images/washing.jpg';
import motor from './images/motor2.jpg';

function About() {
  return (
    <section
      id="about"
      className="py-5 about-container" // Add the custom CSS class for the background color
      style={{ minHeight: '100vh' }}
    >
      <div className="about-container shadow-lg"> {/* Apply custom container class and set background color to dark gray */}
        <h2 className="mb-4 text-center">About us</h2>
        <div className="row">
          {/* First row */}
          <div className="col-md-6 col-lg-4 mb-4">
            <div className='card-img-top'>
              <div className="card-body">
                <h5 className="card-title shadow-lg">Big City Repairing Center</h5>
                <p className="all card-text ">
                Big City Online Repairing Center is your one-stop solution for all your repair needs. With expertise in repairing washing machines, motors, ACs, and various hardware parts, we ensure efficient and reliable service. Our team of skilled technicians is dedicated to providing top-notch repair solutions, ensuring your appliances and hardware operate at their best.
                </p>
                <p>يعد مركز إصلاح  عبر الإنترنت هو الحل الشامل لجميع احتياجات الإصلاح الخاصة بك. بفضل خبرتنا في إصلاح الغسالات والمحركات وأجهزة التكييف وأجزاء الأجهزة المختلفة، فإننا نضمن خدمة فعالة وموثوقة. فريقنا من الفنيين المهرة مكرس لتقديم حلول إصلاح من الدرجة الأولى، مما يضمن تشغيل الأجهزة والأجهزة الخاصة بك بأفضل حالاتها.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="all card-body shadow">
              <img src={washing} className="image card-img-top" alt="Image 1" style={{ height: '300px' }} /> {/* Add fixed height style */}
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="all card-body shadow border-radius">
              <img src={motor} className="image card-img-top" alt="Image 2" style={{ height: '300px' }} /> {/* Add fixed height style */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
